import React from 'react'

const Notification = () => (
  <div className="notice">
    <div className="header-inner" id="modal">
      <p className="notification-title">お知らせ</p>
      <p className="notification-txt">
        コンシェルジュサポートは10時〜18時となります。（土曜午前、日曜は定休日となります。）ご理解のほどお願い申し上げます。
        <br />
        また、シェフルサービスは2025年3月末で終了予定です。そのため、シェフルポイントのご利用期限は2025年2月28日までとなります。
        <br />
        何卒ご理解のほどお願い申し上げます。
      </p>
    </div>
  </div>
)

export default Notification
