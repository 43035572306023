import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearGooglePlace } from '../../actions/googlePlace'
import { fetchRestaurant } from '../../actions/restaurant'
import { updateMenuForm } from '../../actions/menuForm'
import { SideMenu, MenuIcon } from '../../components/menu/SideMenu'
import RestaurantInfo from '../../components/restaurant/RestaurantInfo'
import Review from '../../components/restaurant/Review'
import Embed from '../../components/restaurant/Embed'
import Menu from '../../components/restaurant/Menu'
import RestaurantImage from '../../components/restaurant/RestaurantImage'
import {
  Price,
  ReservationButtonArea,
  ReservationButton,
} from '../../components/restaurant/RestaurantParts'
import { BackButton, ChatButton } from '../../components/common/Buttons'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReminderToSignIn from '../../components/common/ReminderToSignIn'
import AuthComponent, {
  authMapStateToProps,
  authMapDispatchToProps,
} from '../../components/AuthComponent'
import { isMobile } from 'react-device-detect'
import SnsShareButton from '../../components/common/SnsShareButton'
import { PcHeader, SpHeader } from '../../components/top/Header'

function mapStateToProps(state) {
  return Object.assign(authMapStateToProps(state), {
    restaurant: state.restaurant,
    menu: state.menu,
    seatForm: state.seatForm,
    menuForm: state.menuForm,
    googlePlace: state.googlePlace,
  })
}

function mapDispatchToProps(dispatch) {
  return Object.assign(authMapDispatchToProps(dispatch), {
    fetch: bindActionCreators(fetchRestaurant, dispatch),
    updateMenuForm: bindActionCreators(updateMenuForm, dispatch),
    clearGooglePlace: bindActionCreators(clearGooglePlace, dispatch),
  })
}

class RestaurantContainer extends AuthComponent {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    // ログイン後にレストラン詳細画面に戻る
    this.props.authUser.redirectPath = this.props.history.location.pathname

    //  レストラン情報を取得
    this.props.fetch({ restaurantId: this.props.match.params.restaurantId })
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  componentWillUnmount() {
    this.props.clearGooglePlace()
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  isMenu = () => {
    const { data } = this.props.restaurant
    if (Object.keys(data).length === 0) {
      return false
    }
    return data.reservationType === 'chefle'
  }

  isSp = () => {
    return this.state.width < 769
  }

  render() {
    const { authUser } = this.props
    const { data, error } = this.props.restaurant
    const path = window.location.href

    // レストランが存在しない場合にTopへリダイレクトする
    if (error === true) {
      this.props.history.push('/')
    }

    const head = {
      title: `${data.nameJa ? data.nameJa + ' ' : ''}${data.name} - ${
        !!data.subArea ? data.subArea + ', ' : ''
      }${data.area.name}/${data.category.name} | シェフル(chefle) - 出張シェフ`,
      description: data.description,
      page_url: window.location.href,
      image: data.images[0],
    }

    return (
      <>
        <div className="top-page">
          <Helmet>
            <link rel="canonical" href={path} />
            <title>{head.title}</title>
            <meta property="og:title" content={head.title} />
            <meta property="og:description" content={head.description} />
            <meta property="og:image" content={head.image} />
            <meta property="og:url" content={head.page_url} />
            <meta name="description" content={head.description} />
          </Helmet>
          <PcHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'chefle'}
          />
          <SpHeader
            authUser={authUser}
            logout={() =>
              this.props.postSignOut({ data: this.props.authUser.authInfo })
            }
            reservationType={'chefle'}
          />
          <div
            className="restaurant"
            Style={isMobile ? 'margin-top: 0px;' : 'margin-top: 70px;'}
          >
            <RestaurantImage data={data} className="restaurant-chefImage2" />
            <main>
              <div className="main-inner">
                <section className="restaurant-head">
                  <div Style="display:flex; justify-content: space-between;">
                    <ul className="breadcrumb">
                      <SnsShareButton name={data.name} address={data.address} />
                      <li>
                        <Link to="/catering">Home</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="restaurant-name-container">
                    <div>
                      <h1 className="restaurant-name-en">{data.name}</h1>
                      <h1 className="restaurant-name-ja">{data.nameJa}</h1>
                    </div>
                    {/* pcのみ表示 */}
                    {data.chefImage && (
                      <img
                        src={data.chefImage}
                        className="restaurant-img-icon pc"
                        alt="シェフ"
                      />
                    )}
                    <ReservationButton
                      isMenu={this.isMenu()}
                      restaurantId={data.id}
                      isLogin={this.props.authUser.authInfo.isLogin}
                    />
                  </div>
                </section>
                <div className="flexbox">
                  <div className="flex-main">
                    <section className="restaurant-description">
                      <div className="evaluation-detail">
                        <div className="evaluation-detail-container">
                          <Price isMenu={this.isMenu()} restaurant={data} />
                          <ul
                            className={
                              'evaluation-detail-star evaluation-detail-star-0' +
                              data.popularity
                            }
                          >
                            <li>★</li>
                            <li>★</li>
                            <li>★</li>
                            <li>★</li>
                            <li>★</li>
                          </ul>
                        </div>
                      </div>
                      <hr className="restaurant-line" />
                      {data.description && (
                        <>
                          <p className="restaurant-txt">{data.description}</p>
                          <p className="restaurant-chefname">{data.chefName}</p>
                        </>
                      )}
                    </section>
                    {/* Menu Options */}
                    <section>
                      <Menu menus={this.props.menu.data} />
                    </section>
                    {/* Restaurant info(SP) */}
                    {this.isSp() && (
                      <section>
                        <RestaurantInfo
                          restaurant={data}
                          googlePlace={this.props.googlePlace}
                        />
                        <div className="sp">
                          <ReservationButtonArea
                            isMenu={this.isMenu()}
                            restaurantId={data.id}
                            isLogin={this.props.authUser.authInfo.isLogin}
                            style={{
                              height: '60px',
                              lineHeight: '60px',
                            }}
                          />
                        </div>
                      </section>
                    )}
                    {/* レビュー */}
                    <Review
                      rating={this.props.googlePlace.data.rating}
                      reviews={this.props.googlePlace.data.reviews}
                      placeid={data.googlePlaceId}
                    />
                  </div>
                  {/* Restaurant info(PC) */}
                  {!this.isSp() && (
                    <div className="flex-side">
                      <section>
                        <RestaurantInfo
                          restaurant={data}
                          googlePlace={this.props.googlePlace}
                        />
                      </section>
                    </div>
                  )}
                </div>
                {/* instagramm */}
                <section>
                  <Embed instagramUrls={data.instagramUrls} />
                  <div className="sp">
                    <ReservationButtonArea
                      isMenu={this.isMenu()}
                      restaurantId={data.id}
                      isLogin={this.props.authUser.authInfo.isLogin}
                      style={{
                        height: '60px',
                        lineHeight: '60px',
                      }}
                    />
                  </div>
                </section>
              </div>
              <SideMenu
                authUser={this.props.authUser}
                logout={() =>
                  this.props.postSignOut({
                    data: this.props.authUser.authInfo,
                  })
                }
              />
            </main>
            <div className="fixed-footer sp">
              <div className="fixed-footer-inner">
                {this.props.authUser.authInfo.isLogin && (
                  <Link
                    to={`/pickup/restaurants/${data.id}/menu-reservation/form`}
                  >
                    <img
                      src="/assets/img/menu_btn_01.svg"
                      className="fixed-footer-center"
                      alt=""
                    />
                  </Link>
                )}
                {!this.props.authUser.authInfo.isLogin && (
                  <ReminderToSignIn
                    text={
                      <img
                        src="/assets/img/menu_btn_01.svg"
                        className="fixed-footer-center"
                        alt=""
                      />
                    }
                  />
                )}
                <BackButton position={'left'} />
                <ChatButton position={'right'} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestaurantContainer)
